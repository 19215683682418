<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card title="الفلترة" class="mb-2" title-color="primary">
      <div class="vx-row align-center">
        <div class="vx-col md:w-1/3 flex">
          <vs-dropdown
            vs-trigger-click
            class="md:w-1/2 dd-actions cursor-pointer mr-4 mb-4"
          >
            <div
              class="
                p-4
                shadow-drop
                rounded-lg
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-center
                text-lg
                font-medium
                w-32 w-full
              "
            >
              <span class="mr-2">البحث ب {{ getSearchByKey(search_by) }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="search_by = 'starts_with[ref]'">
                <span class="flex items-center">
                  <span>رقم التحويل</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'starts_with[order.id]'">
                <span class="flex items-center">
                  <span>رقم الطلب</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="search_by = 'starts_with[order.user.username]'"
              >
                <span class="flex items-center">
                  <span>اسم المستخدم</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item @click="search_by = 'starts_with[order.user.phone]'">
                <span class="flex items-center">
                  <span>رقم المستخدم</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-input
            placeholder="بحث"
            type="text"
            class="md:w-3/4"
            name="note"
            v-model="searchQuery"
          />
        </div>
      </div>
      <div class="vx-row justify-end">
        <vs-button class="mr-3" color="#7367F0" type="filled" @click="search()">
          بحث
        </vs-button>
        <vs-button class="mr-3" color="warning" type="border" @click="reset()">
          إعادة تعيين
        </vs-button>
      </div>
    </vx-card>
    <vs-table
      ref="table"
      multiple
      v-model="selected"
      :max-items="itemsPerPage"
      :data="transactionsList"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ACTION - DROPDOWN -->

          <!-- DOWNLOAD PDF-->
          <div
            @click="exportPdf"
            class="
              btn-add-new
              p-3
              mb-4
              mr-4
              rounded-lg
              cursor-pointer
              flex
              items-center
              justify-center
              text-lg
              font-medium
              text-base text-primary
              border border-solid border-primary
            "
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />

            <span class="ml-2 text-base text-primary">تنزيل PDF</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
      </div>

      <template slot="thead">
        <vs-th sort-key="id">رقم التحويل</vs-th>
        <vs-th>للطلب</vs-th>
        <vs-th sort-key="id">مبلغ الطلب الكلي</vs-th>
        <vs-th sort-key="total">المدفوع </vs-th>
        <vs-th sort-key="created_at">تاريخ التحويل</vs-th>
        <vs-th>للزبون </vs-th>
        <vs-th>ملاحظة </vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.ref }}</p>
            </vs-td>
            <vs-td>
                {{ tr.order.id }}
              </vs-td>
            <vs-td>
                {{ tr.order.total }}
              </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.amount }}LYD</p>
            </vs-td>

            <vs-td>
              <vs-chip class="product-order-status">
                {{ formatDate(tr.created_at) }}
              </vs-chip>
            </vs-td>
            <vs-td>
              {{ tr.order.user ? tr.order.user.username : "هذا المستخدم قام بمسح حسابه" }}
            <br/>
             {{ tr.order.user ? tr.order.user.phone :'' }}
            </vs-td>
           
           
            <vs-td>
                {{ tr.note || 'null' }} 
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
    class="mt-4"
    :total="metaData.last_page"
    v-model="current_Page"
    @change="getData(current_Page)"
  ></vs-pagination>

    <!-- HIDDEN TABLE OF ORDERS USED FOR PRINTING PDF-->
    <div style="display: none" id="table_body">
      <table id="table_products_pdf" style="width: 100%">
        <thead id="table_products_pdf_header" style="background-color: black">
          <tr id="table_products_pdf_header_row"></tr>
        </thead>
        <tbody id="table_body">
          <tr
            style="width: 100%; margin-top: 1rem; margin-bottom: 1rem"
            v-for="(data, index) in transactionsList"
            :key="index"
          >
            <td style="color: black; font-weight: bold; text-align: center">
              {{ data.ref }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
                {{ data.order.id }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
                {{ data.order.total }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
                <p class="product-category">{{ data.amount }}LYD</p>
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
                {{ formatDate(data.created_at) }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
                {{ data.order.user ? data.order.user.username : "هذا المستخدم قام بمسح حسابه" }}
                <br/>
             {{ data.order.user ? data.order.user.phone :'' }}
            </td>
            <td style="color: black; font-weight: bold; text-align: center">
              {{ data.note }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script>
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      loading: false,
      selected: [],
      search_by: "starts_with[ref]",
      searchQuery: "",
      params: {},
      current_Page: Number(this.$route.query.page) || 1,
      itemsPerPage: 10,
    };
  },

  computed: {
    ...mapGetters("transactions", ["transactionsList", "metaData"]),
  },
  methods: {
    ...mapActions("transactions", ["fetchTransactions"]),
    search() {
      this.params[this.search_by] = this.searchQuery;
      this.getData(1);
    },
    getSearchByKey(value) {
      if (value == "starts_with[ref]") return "رقم التحويل";
      if (value == "starts_with[order.id]") return "رقم الطلب";
      if (value == "starts_with[order.user.username]") return "اسم المستخدم";
      if (value == "starts_with[order.user.phone]") return "رقم المستخدم";
    },
    async exportPdf() {
      var columns = [
        "Transaction Ref",
        "for order",
        "total order amount",
        "paid",
        "created at",
        "from customer",
        "note",
      ];
      // get the products

      await this.fetchTransactions().then((response) => {
        setTimeout(() => {
          let printContents = document.getElementById("table_body");
          printContents.style.display = "table";
          var windosw = window.open();
          windosw.document.body.innerHTML = printContents.innerHTML;
          printContents.style.display = "none";

          let style = document.createElement("style");
          style.innerHTML =
            " @import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap'); body:{ font-family: 'Cairo' } #table_products_pdf_header > tr >th { color: white !important; font-size: 14px; text-align: center; } @page { size: A4; margin: 11mm 17mm 17mm 17mm; } @media print { thead{ -webkit-print-color-adjust: exact; background-color: black; } footer { position: fixed; bottom: 0; } #table-header{ position: fixed; top: 0; left: 0; } html, body { width: 210mm; height: 297mm; -webkit-print-color-adjust: exact; } }";
          windosw.document.body.style.fontFamily = "Cairo";
          windosw.document.head.appendChild(style);

          columns.forEach((h) => {
            let th = windosw.document.createElement("th");
            th.innerHTML = h;
            windosw.document
              .getElementById("table_products_pdf_header_row")
              .appendChild(th);
          });

          setTimeout(() => {
            //windosw.print()
          }, 100);
        }, 800);
      });
    },
    reset() {
      this.params = {};
      this.getData(1);
    },
    getData(page) {
      this.$vs.loading();
      this.params["page"] = page;
      this.params["perPage"] = 10;
      this.fetchTransactions(this.params)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.$vs.loading();
    this.getData(1);
  },
};
</script>
  
<style lang="scss">
</style>
  